import api from '../library/axios';

export default {
  getList(workspace_id, query) {
    return api({
      url: `/api/integration/notification/${workspace_id}`,
      params: query,
    });
  },
  submit(workspace_id, data) {
    return api({
      url: `/api/integration/notification/${workspace_id}`,
      method: 'post',
      data,
    });
  },
  edit(workspace_id, data) {
    return api({
      url: `/api/integration/notification/${workspace_id}/${data._id}`,
      method: 'patch',
      data,
    });
  },
  delete(workspace_id, id) {
    return api({
      url: `/api/integration/notification/${workspace_id}/${id}`,
      method: 'delete',
    });
  },
};
