function generatePassword(length, type = 'random') {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  if (type === 'number') {
    characters = '1234567890';
  } else if (type === 'symbol') {
    characters = '!@#$%^&*()_';
  }
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  if (type === 'random') {
    result += generatePassword(1, 'number');
    result += generatePassword(1, 'symbol');
  }
  return result;
}

const makeid = (length) => generatePassword(length);

export default makeid;
